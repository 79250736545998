import React from "react";
import "./App.css";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import HomeIcon from '@material-ui/icons/Home';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppStyles from "./AppStyles"
import TabPanel from "./TabPanel"
import Home from "./tabs/Home"
export default function App() {

    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const useStyles = AppStyles()
    const classes = useStyles()
    return (
    <div className={"header"}>
        <AppBar position="sticky" className={classes.appBar}>
            <Toolbar>
                <Tabs
                    orientation={"horizontal"}
                    variant={"scrollable"}
                    value={value}
                    textColor="primary"
                    classes={{
                        indicator: classes.indicator
                    }}
                    onChange={handleChange}
                    aria-label="navbar tabs">
                    <Tab icon={<HomeIcon/>} {...a11yProps(0)}/>
                </Tabs>
            </Toolbar>
        </AppBar>
        <div className="tab-parent">
        <TabPanel value={value} index={0}>
        <Home></Home>
      </TabPanel>
        </div>
       
    </div>

)
}