import React from "react";
import cvImage from "../images/sam.jpg"
import GitHub from "@material-ui/icons/GitHub";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup"


export default function Home() {

    return (
        <div className={'button-group'}>
            <div className="image-holder">
            <img className="about-image" src={cvImage} alt="sam-about"></img>
            </div>
                <h2>Sam Ludbrook Chinellato</h2>
                <h3>Cloud Engineer @ IBM</h3>
                <ButtonGroup
                 style = {{
                     backgroundColor: '#f5f5f5'

                 }}
                 aria-label="about me button group" 
                 className="flex-container">
                    <Button className="about-button" href="https://github.com/SamChinellato"><GitHub/></Button>
                    <Button className="about-button" href="https://www.google.co.uk/maps/place/Reading/"><RoomOutlinedIcon/> Reading, UK</Button>
                    <Button className="about-button" href="mailto:samuelechinellato@gmail.com"><MailOutlineIcon/>Email</Button>
                    <Button className="about-button" href="https://www.linkedin.com/in/samchinellato/"><LinkedInIcon/></Button>
                    </ButtonGroup>
                {/* <Button className="cv-button" size={'large'} variant={"contained"} color="primary">Download my CV</Button> */}
            
        </div> 
    )
}