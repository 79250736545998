import React from "react";
import Box from "@material-ui/core/Box"

export default function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return(          
              <div
                role="tabpanel"
                hidden={value !== index}
                id={`app-tabpanel-${index}`}
                aria-labelledby={`app-tab-${index}`}
                {...other}
              >
                {value === index && (
                  <Box>
                    {children}
                  </Box>
                )}
              </div>
            );
}