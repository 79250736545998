import {makeStyles} from "@material-ui/core/styles";
// Define app in JS styling here
export default function AppStyles() {
    return(
    makeStyles({
        root: {
            flexGrow: 1,
        },
        appBar: {
            background : '#f5f5f5',
            color : '#5B676D',
            alignItems: "center",
            minHeight: 50
        },
        indicator : {
            backgroundColor : '#3f51b5',
        }, 
    })
    )
}
