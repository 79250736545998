import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {Route, HashRouter, Switch} from "react-router-dom";
import App from "./App";
import NotFound from './NotFound'

ReactDOM.render(

  <React.StrictMode>
      <div className={"parent"}>
          <HashRouter>
              <Switch>
              <Route component={App} path={"/"} exact={true}/>
              <Route component={NotFound} />
              </Switch>
          </HashRouter>
          <footer className={"site-footer"}>
              <div className={"footer-content"}>
                  Copyright © Sam Chinellato - All rights reserved.
              </div>
          </footer>
      </div>
  </React.StrictMode>,
  document.getElementById('root')
);
serviceWorker.register();
